import React from 'react';
import clsx from 'clsx';
import responsiveImageClasses from './ResponsiveImage.module.css';

type ResponsiveImageProps = {
  desktopImageUrl?: string;
  mobileImageUrl?: string;
  alt?: string;
  className?: string;
};

function ResponsiveImage({
  desktopImageUrl, mobileImageUrl, alt, className
}: ResponsiveImageProps) {
  return (
    <picture className={className}>
      <source
        media="(min-width:651px)"
        srcSet={desktopImageUrl}
      />
      <img
        className={clsx(responsiveImageClasses.image, className)}
        src={mobileImageUrl}
        alt={alt}
        loading="lazy"
      />
    </picture>
  );
}

export default ResponsiveImage;
