import { batch, useDispatch, useSelector } from 'react-redux';
import ProductId from '@/common/ProductId';
import { dealSelectors } from '../slice/deal.slice';
import {
  clearCurrentStep,
  updateDealRecipes,
  userSelectionsSelector
} from '../slice/userSelections.slice';
import { addToDealClickAnalytics } from '../analytics';
import { BuiltPizza } from '@/builders/pizza/dataTransformers/builderTypes';
import { Product } from '@/domain/product/types';
import { AddableCartItemKinds } from '@/api/phdApiV2Client/request.types';
import {
  nonPizzaProductAddToDealClickAnalytics,
  nonPizzaProductAddToDealClickAnalyticsFromRail
} from '@/builders/deals/analytics/analytics';
import DealType from '../DealTypes';
import { Deal } from '../slice/dealTypes';
import { dealWithRecipeAdded } from '../slice/dealMethods';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';

const useAddToDeal = (): ((
  item: BuiltPizza | Product,
  fromInfoRail?: boolean,
  upcharge?: number
) => Deal | null) => {
  const dispatch = useDispatch();
  const deal = useSelector(dealSelectors.selectDeal);
  const { id: stepId, index: currentStepIndex } = useSelector(userSelectionsSelector.selectCurrentStep) ?? {};
  const analytics = useAnalytics();

  const pushAddToDealClickAnalytics = (
    item: BuiltPizza | Product,
    fromInfoRail?: boolean
  ) => {
    const { globalId } = new ProductId(deal?.data.id ?? '');
    const commonObj = {
      deal_id: globalId,
      deal_name: deal?.data.name ?? '',
      product_name_hit: item?.name ?? ''
    };
    if (item.kind === AddableCartItemKinds.BUILT_PIZZA) {
      analytics.push(() => addToDealClickAnalytics(commonObj, item));
    }
    if (item.kind === AddableCartItemKinds.PRODUCT) {
      analytics.push(() => (fromInfoRail
        ? nonPizzaProductAddToDealClickAnalyticsFromRail(commonObj, item)
        : nonPizzaProductAddToDealClickAnalytics(commonObj, item)));
    }
  };

  const addToDeal = (
    item: BuiltPizza | Product,
    fromInfoRail?: boolean,
    upcharge?: number
  ): Deal | null => {
    const dispatchUpdateDealRecipe = () => dispatch(updateDealRecipes([{
      item, stepId, index: currentStepIndex || 0, upcharge
    }]));
    if (deal?.data.type === DealType.SINGLE_STEP) {
      dispatchUpdateDealRecipe();
    } else {
      pushAddToDealClickAnalytics(item, fromInfoRail);
      batch(() => {
        dispatchUpdateDealRecipe();
        dispatch(clearCurrentStep());
      });
    }
    return deal
      ? {
        ...deal,
        userSelections: {
          present: {
            ...deal.userSelections.present,
            recipes: dealWithRecipeAdded(
              deal.userSelections.present.recipes,
              [{ item, stepId, index: currentStepIndex || 0 }]
            )
          },
          past: [],
          future: []
        }
      }
      : null;
  };

  return addToDeal;
};

export default useAddToDeal;
