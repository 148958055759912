import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useRouter } from 'next/router';

import {
  updatePizzaOptions,
  updatePizzaWithUserSelections
} from '../../pizza/slice/pizza.slice';
import usePizzaOptions from '../../pizza/hooks/usePizzaOptions';
import { userSelectionsSelector } from '../slice/userSelections.slice';
import useApplyDealRules from './useApplyDealRules';
import useDeepEffect from '@/common/useDeepEffect';
import { BuiltPizza, DisplayableItem, PizzaUserSelections } from '@/builders/pizza/dataTransformers/builderTypes';
import { AddableCartItemKinds } from '@/api/phdApiV2Client/request.types';
import { openModal } from '@/localization/actions';
import Routes from '@/router/routes';
import { dealDataOOSModalDetails, RETURN_TO_DEALS } from '@/common/Modal/modalDetails';
import useProduct from '@/graphql/hooks/variables/useProductItem';
import { RootState } from '@/rootStateTypes';

const composeUserSelectionsObject = (recipe: BuiltPizza): Partial<PizzaUserSelections> => ({
  finisher: recipe.finisher,
  cheese: recipe.cheese,
  sauce: recipe.sauce,
  crust: recipe.crust,
  size: recipe.size,
  specialInstructions: recipe.specialInstructions,
  meatToppings: recipe.meatToppings,
  veggieToppings: recipe.veggieToppings
});

const outOfStock = (options: DisplayableItem[]) => options
  .reduce((acc, current) => !!current.outOfStock && acc, true);

function usePizzaDataInRedux(): boolean {
  const isYumEcomm: boolean = useSelector(
    (state: RootState) => state.coreConfig.isYumEcomm
  );

  const [openModalDispatched, setOpenModalDispatched] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const currentDealStep = useSelector(userSelectionsSelector.selectCurrentStep);
  const pizzaId = useProduct('items', currentDealStep?.recipeId ?? undefined)?.variables?.itemID;
  const { pizzaOptions: gqlPizzaOptions, loading: gqlLoading } = usePizzaOptions({
    idOrCode: {
      pizzaGlobalId: pizzaId ?? undefined,
      productCode: ''
    },
    skipQuery: isYumEcomm
  });
  const { pizzaOptions, loading } = isYumEcomm
    ? { pizzaOptions: currentDealStep?.recipeOptions.find((pizza) => pizza.id === currentDealStep?.recipeId)?.pizzaBuilderOptions ?? null, loading: false }
    : { pizzaOptions: gqlPizzaOptions, loading: gqlLoading };

  const options = useApplyDealRules(pizzaOptions);
  const recipe = useSelector(userSelectionsSelector.selectCurrentStepDealRecipe);

  useDeepEffect(() => {
    if (options && !openModalDispatched) {
      const { sizes, crusts } = options;

      const outOfStockSizes = sizes && outOfStock(sizes);
      const outOfStockCrusts = crusts && outOfStock(crusts);

      if (outOfStockSizes || outOfStockCrusts) {
        dispatch(openModal({
          ...dealDataOOSModalDetails,
          cta: {
            text: RETURN_TO_DEALS,
            callback: () => {
              router.push(Routes.DEALS);
            }
          },
          hideCloseIcon: true
        }));
        setOpenModalDispatched(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useDeepEffect(() => {
    if (options) {
      dispatch(updatePizzaOptions(options));
      if (recipe?.item && recipe?.item.kind === AddableCartItemKinds.BUILT_PIZZA) {
        const { crust, ...userSelections } = composeUserSelectionsObject(recipe.item);
        if (isYumEcomm && !crust) {
          dispatch(updatePizzaWithUserSelections(userSelections));
        } else {
          dispatch(updatePizzaWithUserSelections({ crust, ...userSelections }));
        }
      }
    }
  }, [options, recipe]);
  return loading;
}

export default usePizzaDataInRedux;
