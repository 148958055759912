import React, { useState } from 'react';
import {
  Grid, CardActionArea, CardContent, Typography, Checkbox
} from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ItemTypes } from '@pizza-hut-us-development/client-core';
import { useDecision } from '@optimizely/react-sdk';
import Portion from '@/common/Portion';
import { hasUpgrade, isAutoAddStep } from '../hooks/useAutoAddingDealHelpers';
import { BuiltPizza, RuleActionChoice } from '@/builders/pizza/dataTransformers/builderTypes';
import { Product } from '@/domain/product/types';
import { AddableCartItemKinds, DealRuleTypes } from '@/api/phdApiV2Client/request.types';
import { isCreateYourOwnPizza } from '@/builders/pizza/identifiers';
import { joinText, joinToppingDescription, Placeable } from '@/common/string-formatter';
import { updateDealRecipes, userSelectionsSelector } from '../slice/userSelections.slice';
import { DealRuleItem, DealStep } from '../slice/dealTypes';
import { useDesktopStyles } from './DealSteps.styles';
import RuleAction from '@/common/RuleAction';
import Badge from '@/coreComponents/boxes/Badge';
import { FRIES } from '@/clientCore/temporaryTransformationalHooks/useCCGetProducts/helpers/constants';

interface Props {
  step: DealStep;
  index: number;
  onStepClick: (dealStep: DealStep, index: number) => void;
  rules: Partial<Record<RuleActionChoice, DealRuleItem[]>>;
  multiStepDecisionEnabled: boolean;
}

function IndividualDealStep({
  step, index, onStepClick, rules, multiStepDecisionEnabled
}: Props): JSX.Element {
  const gridKey = `step-${index + 1}`;
  const dispatch = useDispatch();
  const classes = useDesktopStyles();
  const isAutoStep = isAutoAddStep(step, multiStepDecisionEnabled);
  const isUpgradeable = hasUpgrade(step, multiStepDecisionEnabled);
  const getRecipeById = useSelector(userSelectionsSelector.selectSelectedRecipeByStepId);
  const item = getRecipeById(step.id, index)?.item;
  const [isChecked, toggleCheckbox] = useState(item?.name !== step.recipeOptions[0]?.name);
  const includedRules = rules[RuleAction.INCLUDE];
  const rule = includedRules?.find((includedRule) => includedRule.type === 'SIZE' && includedRule.stepId === step.id);
  const [{ enabled: useWingsModifierDescriptionsEnabled }] = useDecision('fr-web-3991-use_wings_modifier_descriptions');

  const getRecipeOption = (name: string) => step.recipeOptions.find((option) => option.name === name);

  const handleStepClick = (): void => {
    if (isAutoStep && !isUpgradeable) return;
    if (isUpgradeable) {
      toggleCheckbox(!isChecked);
      const breadstick = getRecipeOption('Breadsticks');
      const cheeseStick = getRecipeOption('Cheese Sticks');
      const selectedOption = !isChecked ? cheeseStick : breadstick;

      if (!selectedOption) return;
      const updatedItem: Product = {
        kind: AddableCartItemKinds.PRODUCT,
        type: selectedOption.type,
        id: selectedOption.fullId,
        name: selectedOption.name,
        description: selectedOption.description,
        quantity: 1,
        sodiumWarning: selectedOption.sodiumWarning,
        selectedModifiers: [{
          id: `${selectedOption.fullId}/${rule?.type === 'SIZE' ? DealRuleTypes.SIZE : ''}/${rule?.id}`,
          name: '',
          type: rule?.type || '',
          sodiumWarning: selectedOption.sodiumWarning
        }]
      };

      dispatch(updateDealRecipes([{ item: updatedItem, stepId: step.id, index }]));
      return;
    }
    onStepClick(step, index);
  };

  const joinSummaryText = (itemToJoin: BuiltPizza | Product): string => {
    if (itemToJoin.kind === AddableCartItemKinds.BUILT_PIZZA) {
      const {
        name, size, crust, sauce, cheese, finisher, meatToppings, veggieToppings
      } = itemToJoin;
      const finisherName = finisher && finisher.portion !== Portion.NONE ? finisher.name : null;
      const pizzaDetails = [size?.name, crust?.name, sauce?.name, cheese?.name, finisherName];

      const pizzaName = !isCreateYourOwnPizza(itemToJoin) && name;
      const pizzaDetailsText = joinText(pizzaDetails);
      const toppings = joinToppingDescription([...(meatToppings || []), ...(veggieToppings || [])] as Placeable[]);

      return joinText([pizzaName, pizzaDetailsText, toppings], '\n');
    }

    // TODO: Remove conditional check for Wings and Fries and test with other product types if the description is correct
    if (useWingsModifierDescriptionsEnabled && (itemToJoin.type === ItemTypes.WINGS || itemToJoin.name === FRIES) && itemToJoin.selectedModifiers?.length > 0) {
      return `${itemToJoin.name}, ${itemToJoin.selectedModifiers.map((mod) => mod.name).join(', ')}`;
    }

    return itemToJoin.name;
  };

  return (
    <Grid
      data-testid={gridKey}
      key={gridKey}
      onClick={handleStepClick}
    >
      <CardActionArea className={classes.cardRoot}>
        <CardContent className={classes.cardContent}>
          <Grid className={classes.cardSummary}>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item md zeroMinWidth>
                <Typography
                  variant="h2"
                  aria-label={`step-${index + 1} ${step.name}`}
                >
                  {step.name}
                </Typography>
                <Typography data-testid="deal-summary-text" color={item ? 'initial' : 'error'}>
                  { isAutoStep && `${item?.name} included in this deal` }
                  { !isAutoStep && (item ? joinSummaryText(item) : step.description) }
                </Typography>
              </Grid>
              {!isAutoStep && (<ArrowForwardIos className={classes.caratIcon} />)}
            </Grid>
          </Grid>
          {isUpgradeable
            && (
              <Grid className={classes.checkbox}>
                <Checkbox name="upgrade" checked={isChecked} className={classes.checkboxbox} />
                <Typography className={classes.checkboxText} data-testid="deal-checkbox-text">
                  {isUpgradeable && `Upgrade to ${getRecipeOption('Cheese Sticks')?.name}`}
                </Typography>
                <Badge text="ADD $2" ariaHidden />
              </Grid>
            )}
        </CardContent>
      </CardActionArea>
    </Grid>
  );
}

export default IndividualDealStep;
