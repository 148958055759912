import React, { lazy, ReactElement, Suspense } from 'react';

type IconName = 'info' | 'close';

type IconProps = {
  name: IconName;
  width?: string;
  height?: string;
};

const loadIcon = (name: IconName) => lazy(() => import(`./icons/${name}.tsx`).catch(() => {
  console.warn(`Icon "${name}" not found.`);
  return { default: () => null };
}));

function Icon({
  name, width = '16', height = '16', ...props
}: IconProps): ReactElement {
  const SVG = loadIcon(name);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <Suspense fallback={<></>}>
        <SVG />
      </Suspense>
    </svg>
  );
}

export default Icon;
