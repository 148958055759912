import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';

import { dealSelectors } from '@/builders/deals/slice/deal.slice';
import { updateDealStep, userSelectionsSelector } from '@/builders/deals/slice/userSelections.slice';
import { onDealStepClick } from '@/builders/deals/analytics';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';

import decodeEntities from '@/graphql/helpers/decodeEntities';
import { MELT, PIZZA } from '@/domain/constants';

import { useEditBuilderSkeleton } from '@/common/EditBuilderSkeleton';
import ProductId from '@/common/ProductId';
import formattedPrice from '@/common/formattedPrice';

import useSodiumAlertForStore from '@/clientCore/components/SodiumWarningDisclaimer/hooks/useSodiumAlertForStore';

import {
  BuilderStep,
  DealStep,
  CurrentStep,
  StepType
} from '../types';

export const useDealDetails = () => {
  const dispatch = useDispatch();
  const deal = useSelector(dealSelectors.selectDeal);
  const getRecipeById = useSelector(userSelectionsSelector.selectSelectedRecipeByStepId);
  const analytics = useAnalytics();
  const { isShowSodiumAlertForStore } = useSodiumAlertForStore();
  const showEditBuilderSkeleton = useEditBuilderSkeleton();

  const [multiStepSimplificationDecision] = useDecision('exp-abt-58-multi-step-builder-simplification');

  const onStepClick = (dealStep: DealStep, index: number) => {
    const {
      id, name, description, required, recipeOptions, price
    } = dealStep;
    const stepNumber = (deal?.data.steps.indexOf(dealStep) ?? 0) + 1;
    const item = getRecipeById(id, index)?.item;
    const isMultiRecipe = recipeOptions && recipeOptions.length > 1;
    const [{ id: recipeId, type: recipeType, name: recipeName }] = recipeOptions || [];
    const dealGlobalId = new ProductId(deal?.data.id || '').globalId;

    analytics.push(() => onDealStepClick({
      step_name: name || '',
      deal_id: dealGlobalId || '',
      deal_name: deal?.data.name || '',
      ...(!isMultiRecipe ? { product_name_hit: recipeName || '' } : {}),
      step_number: stepNumber
    }));

    const pizzaStepType = isMultiRecipe ? StepType.MULTI_PIZZA_RECIPE : StepType.SINGLE_PIZZA_RECIPE;
    const currentStepType = (recipeType === PIZZA || recipeType === MELT) ? pizzaStepType : StepType.MENU_RECIPE;

    if (item) {
      const { globalId } = new ProductId(item.id || '');
      const step: BuilderStep = {
        type: (item.type === PIZZA || item.type === MELT) ? StepType.SINGLE_PIZZA_RECIPE : currentStepType,
        recipeId: globalId,
        recipeType,
        recipeOptions,
        id,
        name,
        description,
        price,
        index
      };

      dispatch(updateDealStep(step));
    } else {
      const step: CurrentStep = {
        type: currentStepType,
        recipeId: isMultiRecipe ? null : recipeId,
        recipeType: isMultiRecipe ? null : recipeType,
        name,
        description,
        required,
        id,
        price,
        recipeOptions: dealStep?.recipeOptions || [],
        index
      };

      dispatch(updateDealStep(step));
    }
  };

  // TODO: DTG-1109 - use condition below to trigger advisory modal for incomplete selections when clicking `Add to order`
  // const showAddToCart = deal?.data.steps.length === deal?.userSelections?.present?.recipes?.length;

  const totalPrice = deal?.userSelections?.present?.recipes?.reduce((acc, recipe) => {
    if (!recipe || !recipe?.upcharge) return acc;
    return acc + recipe.upcharge;
  }, deal?.data?.price || 0);

  const summary = deal && {
    id: deal?.data?.id,
    price: formattedPrice(deal?.data?.price),
    totalPrice: formattedPrice(totalPrice),
    name: decodeEntities(deal?.data?.name),
    image: deal?.data?.image
  };

  return {
    deal,
    onStepClick,
    summary,
    showEditBuilderSkeleton,
    multiStepSimplificationDecision,
    isShowSodiumAlertForStore
  };
};
