import React, {
  ReactElement, SyntheticEvent, useState
} from 'react';
import clsx from 'clsx';
import { Box, Typography } from '@material-ui/core';

import InfoRail from '@/common/components/InfoRail';
import InfoRailStyles from '@/common/components/InfoRail/InfoRail.styles';

import {
  Card, CardHeader, CardContent, CardFooter
} from '../../atoms/Card';
import Icon from '../../atoms/Icon';
import Button from '../../atoms/Button';
import Badge from '../../atoms/Badge';
import ResponsiveImage from '../../atoms/ResponsiveImage';

import css from './ProductTile.module.css';

const FALLBACK_IMAGE = '/images/fallback-product-image.jpeg';

// PRODUCT TILE
interface ProductTileProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  testId?: string;
}

const ProductTile = ({
  testId,
  className,
  children
}: ProductTileProps): ReactElement => (
  <Card testId={testId} className={clsx(css['product-tile'], className)}>
    <CardHeader />
    {children}
  </Card>
);

// PRODUCT TILE CONTENT
interface ProductTileContentProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string | undefined;
}

const ProductTileContent = ({ className, ...props }: ProductTileContentProps): ReactElement => (
  <CardContent className={clsx(css['product-tile__content'], className)} {...props} />
);

// CTA SECTION
interface ProductTileCtaSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string | undefined;
}

const ProductTileCtaSection = ({ className, ...props }: ProductTileCtaSectionProps): ReactElement => (
  <div className={clsx(css['product-tile__cta'], className)} {...props} />
);

// PRODUCT DETAILS
interface ProductDetailsProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string | undefined;
}

const ProductDetails = ({ className, ...props }: ProductDetailsProps): ReactElement => (
  <div className={clsx(css['product-tile__product-details'], className)} {...props} />
);

// TITLE
interface ProductTitleProps extends React.HTMLAttributes<HTMLParagraphElement> {
  className?: string | undefined;
}

const ProductTitle = ({ className, ...props }: ProductTitleProps): ReactElement => (
  <p className={clsx(css['product-tile__title'], className)} {...props} />
);

// SUBTITLE
interface ProductSubtitleProps extends React.HTMLAttributes<HTMLParagraphElement> {
  className?: string | undefined;
}

const ProductSubtitle = ({ className, ...props }: ProductSubtitleProps): ReactElement => (
  <p data-testid="product-subtitle" className={className} {...props} />
);

// INFO BUTTON
interface ProductTileInfoButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string | undefined;
  productName?: string;
  productDescription?: string;
}

const ProductTileInfoButton = ({
  className, productName, productDescription
}: ProductTileInfoButtonProps): ReactElement | null => {
  const [isInfoRailOpen, setIsInfoRailOpen] = useState(false);

  const classesInfoRail = InfoRailStyles();

  const handleOpenInfoRail = (e: SyntheticEvent): void => {
    e?.preventDefault();
    e?.stopPropagation();
    setIsInfoRailOpen(true);
  };

  if (!productName || !productDescription) {
    return null;
  }

  return (
    <>
      <InfoRail visible={isInfoRailOpen} onClose={() => setIsInfoRailOpen(false)}>
        <Box p={2}>
          <Typography component="p" className={classesInfoRail.subtitle}>
            {productName}
          </Typography>
          <Typography className={classesInfoRail.description}>
            {productDescription}
          </Typography>
        </Box>
      </InfoRail>
      <Button
        aria-label={`Product Info ${productName}`}
        aria-haspopup="dialog"
        className={clsx(css['product-tile__info-icon'], className)}
        onClick={handleOpenInfoRail}
        variant="ghost"
      >
        <Icon name="info" />
      </Button>
    </>
  );
};

// IMAGE
interface ProductImageProps extends React.HTMLAttributes<HTMLImageElement> {
  className?: string | undefined;
  imageURL?: string;
  imageAltText?: string;
  productName: string;
}

const ProductImage = ({
  className, imageURL, imageAltText, productName, ...props
}: ProductImageProps): ReactElement => (
  <ResponsiveImage
    desktopImageUrl={imageURL || FALLBACK_IMAGE}
    mobileImageUrl={imageURL || FALLBACK_IMAGE}
    alt={imageAltText ?? productName}
    className={clsx(css['product-tile__image-container'], className)}
    {...props}
  />
);

// PRICE
interface ProductPriceProps extends React.HTMLAttributes<HTMLParagraphElement> {
  className?: string | undefined;
}

const ProductPrice = ({ className, ...props }: ProductPriceProps): ReactElement => (
  <p className={className} {...props} />
);

// UPCHARGE BADGE
interface ProductUpchargeBadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string | undefined;
  upchargeText?: string;
  upchargeAmount: string;
}

const ProductUpchargeBadge = ({
  className, upchargeText = 'Add', upchargeAmount, ...props
}: ProductUpchargeBadgeProps): ReactElement => (
  <Badge variant="secondary" {...props}>{upchargeText} {upchargeAmount}</Badge>
);

// PRODUCT TILE FOOTER
interface ProductTileFooterProps extends React.HTMLAttributes<HTMLElement> {
  className?: string | undefined;
}
const ProductTileFooter = ({ className, ...props }: ProductTileFooterProps): ReactElement => (
  <CardFooter className={className} {...props} />
);

export {
  ProductTile,
  ProductTileContent,
  ProductDetails,
  ProductTileInfoButton,
  ProductTitle,
  ProductImage,
  ProductSubtitle,
  ProductPrice,
  ProductUpchargeBadge,
  ProductTileCtaSection,
  ProductTileFooter
};
