import { CurrentStep, DealStep } from '../slice/dealTypes';
import StepType from '../StepType';

export type { DealStep, CurrentStep };

export { StepType };

export type BuilderStep = Pick<CurrentStep,
| 'type'
| 'recipeId'
| 'recipeType'
| 'id'
| 'name'
| 'description'
| 'price'
| 'recipeOptions'
| 'index'
>;

export type DealDetailsProps = {
  isEditFlow?: boolean;
};
