import React from 'react';
import { Skeleton } from '@material-ui/lab';
import CenteredContainer from '@/common/CenteredContainer';
import css from './MultiRecipeSelection.module.css';

function MultiRecipeSelectionSkeleton(): JSX.Element {
  return (
    <>
      <CenteredContainer>
        <div className={css.container} data-testid="multi-recipe-selection">
          <Skeleton variant="rect" height={48} width={250} className={css['upcharge-recipes__heading']} />
          <Skeleton
            key="cyo-skeleton"
            variant="rect"
            height={138}
            className={css['product-tile__skeleton']}
          />

          <Skeleton variant="rect" height={32} width={200} className={css['upcharge-recipes__heading']} />
          <div className={css['upcharge-recipes__container']}>
            {
              Array.from({ length: 7 }, (_, k) => (
                <Skeleton
                  key={k}
                  variant="rect"
                  height={138}
                  className={css['product-tile__skeleton']}
                />
              ))
            }
          </div>
        </div>
      </CenteredContainer>
    </>
  );
}

export default MultiRecipeSelectionSkeleton;
