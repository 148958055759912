import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateDealStep, userSelectionsSelector } from '@/builders/deals/slice/userSelections.slice';
import { dealSelectors } from '@/builders/deals/slice/deal.slice';
import useSodiumAlertForStore from '@/clientCore/components/SodiumWarningDisclaimer/hooks/useSodiumAlertForStore';
import {
  SodiumWarningDisclaimer
} from '@/clientCore/components/SodiumWarningDisclaimer/components/SodiumWarningDisclaimer';
import { isCyo, isMeltProduct } from '@/clientCore/menu/utils/pizzaCategories';
import { CRUST_SUBTYPE, SIZE_SUBTYPE } from '@/clientCore/menu/utils/pizzaNutrition';
import { useCCGetDealBuilderQuery } from '@/clientCore/temporaryTransformationalHooks/useCCGetDealBuilderQuery';
import CenteredContainer from '@/common/CenteredContainer';
import CategoryTitle from '@/common/components/CategoryTitle';
import formattedPrice from '@/common/formattedPrice';
import ProductId from '@/common/ProductId';
import { getSizeCrust } from '@/common/ProductDetails/hooks/useGetSizeCrustDetails';
import CaloriesDisclaimer from '@/common/CaloriesDisclaimer/CaloriesDisclaimer';

import { getNutritionInfo } from '../../utils/pizzaNutrition';
import { getRecipeUpchargeAmount } from '../../utils/pizzaPrices';
import css from './MultiRecipeSelection.module.css';
import MultiRecipeSelectionSkeleton from './MultiRecipeSelection.skeleton';
import MultiRecipeSelectionTile, { RecipeTileData } from './MultiRecipeSelectionTile';
import { CurrentStep, DealRecipe } from '@/builders/deals/slice/dealTypes';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { onPizzaMenuTileClickInsideDeal } from '@/builders/deals/analytics';
import { DealPizzaBuilder } from '@/builders/deals/DealStepToRender/DealStep';
import { BuiltPizza, PizzaIngredient } from '@/builders/pizza/dataTransformers/builderTypes';
import useAddToDeal from '@/builders/deals/hooks/useAddToDeal';
import { AddableCartItemKinds } from '@/api/phdApiV2Client/request.types';
import { IsCyo, MenuRecipe } from '@/menu/pizza/pizzaMenuTypes';
import { getMenuItems, onMenuInitialLoadAnalytics } from '@/dataAnalytics/dataAnalyticsHelper';
import { useApplicationInitialized } from '@/hooks/useApplicationInitialized';
import { MELTS_RECIPE_PAGE_VIEW, PIZZA_RECIPE_PAGE_VIEW } from '@/menu/pizza/constants';
import { getOrInitializeOptimizely } from '../../../../../optimizely/optimizely';

type PizzaMap = {
  cyoRecipes: RecipeTileData[];
  upchargeRecipes: RecipeTileData[];
};
interface Props {
  isEditFlow?: boolean;
}

const buildPizza = (recipeId: string, recipes: DealRecipe[]): BuiltPizza | null => {
  const recipe = recipes?.find((r) => r.id === recipeId);
  if (!recipe) return null;

  const selectedCrust = recipe.pizzaBuilderOptions?.crusts?.find((crust) => crust.selected);
  const selectedSize = recipe.pizzaBuilderOptions?.sizes?.find((size) => size.selected);
  const selectedCheese = recipe.pizzaBuilderOptions?.cheeses?.find((cheese) => cheese.selected)?.portions?.find((portion) => portion.selected);
  const selectedSauce = recipe.pizzaBuilderOptions?.sauces?.find((sauce) => sauce.selected)?.portions?.find((portion) => portion.selected);
  const selectedMeatToppings = recipe.pizzaBuilderOptions?.meatToppings?.filter((topping) => topping.selected);
  const selectedVeggieToppings = recipe.pizzaBuilderOptions?.veggieToppings?.filter((topping) => topping.selected);
  const selectedFinisher = recipe.pizzaBuilderOptions?.finishers?.find((finisher) => finisher.selected);
  const selectedBy = 'system';

  const builtPizza: BuiltPizza = {
    crust: { ...selectedCrust, selectedBy } as PizzaIngredient,
    cheese: { ...selectedCheese, selectedBy } as PizzaIngredient,
    sauce: { ...selectedSauce, selectedBy } as PizzaIngredient,
    size: { ...selectedSize, selectedBy } as PizzaIngredient,
    meatToppings: selectedMeatToppings?.map((topping) => ({ ...topping, selectedBy })) as PizzaIngredient[],
    veggieToppings: selectedVeggieToppings?.map((topping) => ({ ...topping, selectedBy })) as PizzaIngredient[],
    finisher: null,
    kind: AddableCartItemKinds.BUILT_PIZZA,
    quantity: 1,
    specialInstructions: '',
    id: recipeId,
    price: recipe.price,
    type: 'PIZZA',
    isCYO: IsCyo.FALSE
  };

  if (selectedFinisher) {
    builtPizza.finisher = { ...selectedFinisher, selectedBy } as PizzaIngredient;
  }

  return builtPizza;
};

function MultiRecipeSelection(props: Props): JSX.Element {
  const { id: dealId, name: dealName } = useSelector(dealSelectors.selectDealSummary);
  const { rawData, loading: isLoading } = useCCGetDealBuilderQuery({ itemId: dealId });
  const appInitialized = useApplicationInitialized();
  const optimizelyInstance = getOrInitializeOptimizely();
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const addToDeal = useAddToDeal();
  const hasPushedPageLoadAnalytics = useRef(false);

  const currentStep = useSelector(userSelectionsSelector.selectCurrentStep);
  const recipes = useSelector(
    userSelectionsSelector.selectRecipesForCurrentStep
  );
  const stepData = rawData?.steps?.find((step) => step.id === currentStep?.id);

  const { isShowSodiumAlertForStore } = useSodiumAlertForStore();
  const isMelt = isMeltProduct(dealName);

  useEffect(() => {
    if (appInitialized && !hasPushedPageLoadAnalytics.current && stepData?.recipes?.length) {
      const label = 'Deals';
      const items = getMenuItems(stepData?.recipes as unknown as MenuRecipe[], label);
      const pageView = isMelt ? MELTS_RECIPE_PAGE_VIEW : PIZZA_RECIPE_PAGE_VIEW;
      analytics.push(() => onMenuInitialLoadAnalytics(
        label,
        items,
        dealId,
        dealName
      ));

      optimizelyInstance?.track(pageView);
      hasPushedPageLoadAnalytics.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealId, dealName, hasPushedPageLoadAnalytics.current, appInitialized, isMelt, stepData?.recipes?.length]);

  const handleCustomizeClick = (recipeId: string) => {
    const recipe = recipes?.find((r) => r.id === recipeId);
    const recipeType = recipe?.type ?? '';
    const newCurrentStep: Partial<CurrentStep> = {
      ...currentStep,
      recipeId,
      recipeType
    };
    dispatch(updateDealStep(newCurrentStep));

    if (dealId && dealName && recipe) {
      const selectedPizzaIndex = recipes?.indexOf(recipe);
      analytics.push(() => onPizzaMenuTileClickInsideDeal(
        dealName,
        dealId,
        recipe,
        selectedPizzaIndex ?? 0
      ));
    }
  };

  const handleAddToDeal = (recipeId: string, upcharge: number) => {
    if (!recipes) return;
    const pizza = buildPizza(recipeId, recipes);
    if (pizza) {
      addToDeal(pizza, false, upcharge);
    }
  };

  if (isLoading) {
    return (
      <MultiRecipeSelectionSkeleton />
    );
  }

  if (currentStep?.recipeId) return <DealPizzaBuilder {...props} />;

  if (!stepData) {
    return <div>Step not found</div>; // TODO: Error handling
  }

  const pizzaMap = stepData.recipes?.reduce((map: PizzaMap, currrentRecipe) => {
    const {
      id, name, description, imageURL, selectedOptions, options, outOfStock
    } = currrentRecipe;
    const { globalId: productId } = new ProductId(id ?? '');
    const upchargeAmount = getRecipeUpchargeAmount(options);
    const isCyoRecipe = !!isCyo(currrentRecipe);

    let updatedMap: PizzaMap;

    const recipeData: RecipeTileData = {
      id,
      isCyo: isCyoRecipe,
      name,
      description,
      outOfStock,
      imageURL,
      sizeCrustSelection: isCyoRecipe ? '' : getSizeCrust(selectedOptions, [SIZE_SUBTYPE, CRUST_SUBTYPE]),
      upchargeAmount: upchargeAmount === 0 || outOfStock ? '' : formattedPrice(upchargeAmount, upchargeAmount % 100 === 0),
      onCustomize: () => handleCustomizeClick(productId),
      onAddToDeal: () => handleAddToDeal(productId, upchargeAmount),
      nutritionInfo: isCyoRecipe ? '' : getNutritionInfo(selectedOptions),
      isMelt
    };

    if (recipeData.isCyo) {
      updatedMap = { ...map, cyoRecipes: [...map.cyoRecipes, recipeData] };
    } else {
      updatedMap = { ...map, upchargeRecipes: [...map.upchargeRecipes, recipeData] };
    }

    return updatedMap;
  }, { cyoRecipes: [], upchargeRecipes: [] });

  return (
    <CenteredContainer>
      <div className={css.container} data-testid="multi-recipe-selection">
        {isMelt ? <CategoryTitle title="Melts" />
          : <CategoryTitle title={stepData.name ?? ''} />}

        {pizzaMap.cyoRecipes?.map(
          (product) => (
            <MultiRecipeSelectionTile key={product.id} product={product} stepName={stepData.name} />)
        )}

        {!isMelt
          && <h2 className={css['upcharge-recipes__heading']}>Or upgrade to a recipe</h2>}

        <div className={css['upcharge-recipes__container']}>
          {pizzaMap.upchargeRecipes?.map(
            (product) => (
              <MultiRecipeSelectionTile key={product.id} product={product} stepName={stepData.name} />)
          )}
        </div>

        <CaloriesDisclaimer />
        {isShowSodiumAlertForStore && <SodiumWarningDisclaimer />}
      </div>
    </CenteredContainer>
  );
}

export { MultiRecipeSelection };
