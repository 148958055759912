import { makeStyles } from '@material-ui/core';
import { largeStartBreakpoint, mobileStartBreakpoint } from '@/materialUi/theme';

export const useStyles = makeStyles((theme) => ({
  dealName: {
    padding: '21px 7px 0px 14px',
    margin: '0',
    boxSizing: 'border-box',
    fontSize: '24px',
    lineHeight: '29px',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      fontSize: '32px',
      padding: 'unset',
      lineHeight: '36px'
    },
    [theme.breakpoints.up(largeStartBreakpoint)]: {
      fontSize: '48px',
      lineHeight: '58px'
    }
  },
  xsMarginLeft: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '16px'
    }
  },
  buildYourDealText: {
    fontFamily: 'open_sans_semi',
    textTransform: 'uppercase'
  }
}));

export default { useStyles };
