import Grid from '@material-ui/core/Grid';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import DealType from '@/builders/deals/DealTypes';
import OrderLevelDeal from '@/builders/deals/OrderLevelDeal';
import { ChildDealStep, DealData } from '@/builders/deals/slice/dealTypes';
import { clearPizza } from '@/builders/pizza/slice/pizza.slice';
import { dealNotAvailableModalDetails } from '@/common/Modal/modalDetails';
import { openModal } from '@/localization/actions';
import ParentChildDealMenu from '@/menu/deals/LocalizedMenu/ParentChildDealMenu/ParentChildDealMenu';
import Routes from '../../../router/routes';
import DealStepToRender from '../DealStepToRender';
import useDeal, { isDealData, Status } from '../hooks/useDeal';
import { clearDeal, updateDeal } from '../slice/deal.slice';
import telemetry from '@/telemetry';
import { localizationSelectors } from '@/localization/localizationSelectors';
import useDealOtherOccasionModal from '@/builders/deals/hooks/useDealOtherOccasionModal';
import { getGlobalId } from '@/cart/helpers/getGlobalId';
import { setActiveDealId } from '../../../../optimizely/utils/attributeHelpers';
import { RootState } from '@/rootStateTypes';
import { clearDealToDisplay } from '@/builders/deals/slice/userSelections.slice';

interface ContainerProps {
  dealData: DealData | Status;
  isEditFlow?: boolean;
}

interface OuterProps {
  privateCode: string;
  publicCode?: string | null;
}

type Container = React.ComponentType<ContainerProps>;

export const DealBuilderContainer = ({ dealData: result, isEditFlow = false }: ContainerProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const isYumEcomm = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const type = isDealData(result) ? result.type : '';

  useEffect(() => () => {
    batch(() => {
      dispatch(clearPizza());
      dispatch(clearDeal());
      if (isYumEcomm) {
        dispatch(clearDealToDisplay());
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PageToRender = useMemo(() => {
    if (!isDealData(result)) return null;

    switch (type) {
      case DealType.PARENT:
        return <ParentChildDealMenu title={result.name} items={result.steps as ChildDealStep[]} />;

      case DealType.ORDER_LEVEL:
        return <OrderLevelDeal isEditFlow={isEditFlow} />;

      default:
        return <DealStepToRender isEditFlow={isEditFlow} />;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <Grid data-testid="deals-page">
      {PageToRender}
    </Grid>
  );
};

const withDataInRedux = (Component: Container) => (
  { privateCode, publicCode }: OuterProps
): JSX.Element => {
  const dispatch = useDispatch();
  const router = useRouter();
  const occasion = useSelector(localizationSelectors.occasion);
  const otherOccasionModalDetails = useDealOtherOccasionModal();
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const isCallYumMarketingCodeQuery = !!publicCode && isYumEcomm;
  const publicCodeRef = useRef(publicCode);
  const result = useDeal(privateCode, publicCode || '', isCallYumMarketingCodeQuery);
  const resultIsDeal = isDealData(result);

  useEffect(() => {
    if (resultIsDeal) {
      const globalDealId = getGlobalId(result?.id);
      setActiveDealId(globalDealId);
      dispatch(updateDeal(result));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultIsDeal, privateCode]);

  const unavailableStatus = [Status.Unavailable, Status.AvailableInOtherOccasion];

  useEffect(() => {
    const dealIsAvailableInOtherOccasion = result === Status.AvailableInOtherOccasion;

    if (!resultIsDeal && unavailableStatus.includes(result)) {
      router.push(Routes.DEALS);
      if (dealIsAvailableInOtherOccasion && !!otherOccasionModalDetails) {
        telemetry.addCustomEvent('Deal not available for this occasion', {
          'public-code': publicCodeRef.current,
          'private-code': privateCode,
          occasion
        });
        dispatch(openModal(otherOccasionModalDetails({
          currentOccasion: occasion,
          publicCode: publicCodeRef.current,
          privateCode
        })));
        return;
      }

      telemetry.addCustomEvent('Deal unavailable', { 'private-code': privateCode });
      if (!isYumEcomm || isCallYumMarketingCodeQuery) {
        dispatch(openModal(dealNotAvailableModalDetails));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, privateCode]);

  if (result === Status.Unavailable) {
    return <div data-testid="deals-page" />;
  }

  return (
    <Component
      dealData={result}
    />
  );
};

export default withDataInRedux(DealBuilderContainer);
