import React, { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useDecision } from '@optimizely/react-sdk';

import { selectors } from '@/builders/pizza/slice/pizza.slice';
import { clearDeal } from '@/builders/deals/slice/deal.slice';
import { clearDealToDisplay, updateDealRecipes } from '@/builders/deals/slice/userSelections.slice';
import { useLineup } from '@/builders/deals/hooks/useLineup';
import {
  onAddMultiStepDealToOrderButtonClick,
  onAddMultiStepDealToOrderSuccess
} from '@/builders/deals/analytics/analytics';
import DealType from '@/builders/deals/DealTypes';

import { openModal } from '@/localization/actions';

import {
  getDealErrorModalDetails,
  NOT_COMBINABLE_DEALS_TITLE
} from '@/common/Modal/modalDetails';
import Routes from '@/router/routes';
import useDeepEffect from '@/common/useDeepEffect';

import { RootState } from '@/rootStateTypes';
import { MELT, PIZZA } from '@/domain/constants';

import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { onAddToOrderButtonSuccess } from '@/dataAnalytics/dataAnalyticsHelper';

import { getOrInitializeOptimizely } from '../../../../../optimizely/optimizely';
import useAddToCart from '@/cart/hooks/useAddToCart';

const getDeal = (state: RootState) => state.domain.deal;

const ADD_DEAL_TO_CART_BUTTON_TEXT = 'Add to order';
export const ADVISORY_MODAL = {
  TITLE: 'Complete Your Selections',
  BODY: 'You must choose all of your items before adding this deal to your cart.',
  CTA_TEXT: 'OK'
};

export const useAddDealToCart = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const { addToCart } = useAddToCart();

  const router = useRouter();
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const { isLineup } = useLineup();

  const deal = useSelector(getDeal);
  const pizza = useSelector(selectors.selectCartAddablePizza);
  const isYumEcomm = useSelector((state: RootState) => state.coreConfig.isYumEcomm);

  const [{ enabled: unhideUpsellPageEnabled }] = useDecision('fr-web-3805-yum-unhide-upsell-page');

  const isMultiStepDeal = deal?.data?.type === DealType.MULTI_STEP;

  useDeepEffect(() => {
    const { recipeType, id: stepId, index } = deal.userSelections.present.currentStep ?? {};
    if (!isMultiStepDeal && (recipeType === PIZZA || recipeType === MELT)) {
      dispatch(updateDealRecipes([{ item: pizza, stepId, index: index || 0 }]));
    }
  }, [pizza]);

  const showAdvisoryModal = () => {
    dispatch(openModal({
      title: ADVISORY_MODAL.TITLE,
      body: ADVISORY_MODAL.BODY,
      cta: {
        text: ADVISORY_MODAL.CTA_TEXT
      },
      hideCloseIcon: true
    }));
  };

  const handleOnSuccessRedirect = () => {
    const redirectRoute = isYumEcomm && !unhideUpsellPageEnabled ? Routes.DEALS : Routes.UPSELL;

    router.push(redirectRoute).then(() => {
      batch(() => {
        dispatch(clearDeal());
        dispatch(clearDealToDisplay());
      });
    });
  };

  const handleDealAddedToCartSuccess = () => {
    const globalDealId = deal.data?.id;
    const optimizelyInstance = getOrInitializeOptimizely();

    analytics.push(() => (isMultiStepDeal
      ? onAddMultiStepDealToOrderSuccess(deal)
      : onAddToOrderButtonSuccess(deal, isLineup)));

    optimizelyInstance?.track('AddToOrder', { dealId: globalDealId as string });

    handleOnSuccessRedirect();
    setLoading(false);
  };

  const handleDealAddedToCartError = (err?: Error) => {
    const dealErrorModalDetails = getDealErrorModalDetails(NOT_COMBINABLE_DEALS_TITLE, err!.message);

    dispatch(openModal(dealErrorModalDetails));
    setLoading(false);
  };

  const handleAddDealToCart = () => {
    const isDealStepsCompleted = (deal?.data?.steps?.length ?? 0) === (deal?.userSelections?.present?.recipes?.length ?? 0);

    if (!isDealStepsCompleted) {
      showAdvisoryModal();
      return;
    }

    setLoading(true);
    if (isMultiStepDeal) {
      analytics.push(() => onAddMultiStepDealToOrderButtonClick(deal));
    }

    addToCart(
      deal,
      handleDealAddedToCartSuccess,
      handleDealAddedToCartError
    );
  };

  return {
    handleAddDealToCart,
    loading,
    ADD_DEAL_TO_CART_BUTTON_TEXT
  };
};
