import * as React from 'react';
import clsx from 'clsx';
import css from './Card.module.css';

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  testId?: string;
  className?: string | undefined;
}

const Card = React.forwardRef<
HTMLDivElement,
CardProps
>(({ testId, className, ...props }, ref) => (
  <div
    data-testid={testId}
    ref={ref}
    className={clsx(
      css.card,
      className
    )}
    {...props}
  />
));

Card.displayName = 'Card';

interface CardHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string | undefined;
}

const CardHeader = React.forwardRef<
HTMLDivElement,
CardHeaderProps
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={clsx(css.card__header, !props.children && css['card__header--empty'], className)}
    {...props}
  />
));

CardHeader.displayName = 'CardHeader';

interface CardContentProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string | undefined;
}

const CardContent = React.forwardRef<
HTMLDivElement,
CardContentProps
>(({ className, ...props }, ref) => (
  <div ref={ref} className={clsx(css.card__content, className)} {...props} />
));
CardContent.displayName = 'CardContent';

interface CardFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string | undefined;
}

const CardFooter = React.forwardRef<
HTMLDivElement,
CardFooterProps
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={clsx(css.card__footer, className)}
    {...props}
  />
));
CardFooter.displayName = 'CardFooter';

export {
  Card, CardHeader, CardContent, CardFooter
};
