import React, {
  useEffect, useMemo, useState
} from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { sortBy } from 'remeda';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useDecision } from '@optimizely/react-sdk';
import { ProductUpchargeBadge } from '@shared-components/molecules/ProductTile';
import LocalizedProductTile from '@/common/components/LocalizedProductTile';
import { useVersionQueryWithAvailability } from '@/graphql/hooks/useVersionQueryWithAvailability';
import PRODUCTS_BY_ID from '@/graphql/dynamicQueries/productsById';
import CategoryHeader from '@/common/components/CategoryHeader';
import CaloriesDisclaimer from '@/common/CaloriesDisclaimer/CaloriesDisclaimer';
import { DisplayableProduct, Product } from '@/domain/product/types';
import CenteredContainer from '@/common/CenteredContainer';
import colors from '@/common/colors';
import { DealRecipe } from '../../slice/dealTypes';
import useAddToDeal from '../../hooks/useAddToDeal';
import { ProductsByIdQueryResponse } from '@/graphql/types/fragment/Products';
import DealType from '../../DealTypes';
import useAddToCart from '@/cart/hooks/useAddToCart';
import { dealSelectors } from '../../slice/deal.slice';
import {
  clearDealRecipes,
  userSelectionsSelector
} from '../../slice/userSelections.slice';
import { openModal } from '@/localization/actions';
import {
  cartErrorModalDetails,
  dealDataOOSModalDetails,
  RETURN_TO_DEALS
} from '@/common/Modal/modalDetails';
import { CheckAvailabilityVariables } from '@/graphql/hooks/variables/useCheckAvailabilityVariables';
import { useApplyDealRulesForNonPizzaProduct } from '@/builders/deals/hooks/useApplyDealRules';
import { onNonPizzaMenuPageLoadInDeal } from '@/menu/categories/categories.analytics';
import { getProductsAsItems } from '@/dataAnalytics/dataAnalyticsHelper';
import Routes from '@/router/routes';
import useDeepEffect from '@/common/useDeepEffect';
import { onGqlError } from '@/graphql/errors/actions';
import QueryName from '@/graphql/errors/constants';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { mobileStartBreakpoint } from '@/materialUi/theme';
import { RootState } from '@/rootStateTypes';
import { SodiumWarningDisclaimer } from '@/clientCore/components/SodiumWarningDisclaimer/components/SodiumWarningDisclaimer';
import useSodiumAlertForStore from '@/clientCore/components/SodiumWarningDisclaimer/hooks/useSodiumAlertForStore';
import { ButtonProps } from '@/coreComponents/buttons/SelectorButton/SelectorButton';
import { useCCGetDealBuilderQuery } from '@/clientCore/temporaryTransformationalHooks/useCCGetDealBuilderQuery';
import formattedPrice from '@/common/formattedPrice';

const useStyles = makeStyles((theme) => ({
  subheading: {
    fontSize: '14px',
    color: colors.gray900,
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontFamily: 'open_sans',
    fontWeight: 700,
    lineHeight: '21px',
    paddingTop: '13px',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      PaddingTop: 'unset'
    }
  },
  mobileSubheading: {
    margin: '24px 16px'
  },
  tilesGrid: {
    display: 'grid',
    gridTemplateRows: '1fr',
    gridGap: '24px',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      marginTop: '24px'
    }
  }
}));

interface ProductMenuStepProps {
  stepName: string;
  recipes: DealRecipe[];
}

const ProductMenuStep = ({
  stepName,
  recipes
}: ProductMenuStepProps): JSX.Element => {
  const isYumEcomm: boolean = useSelector(
    (state: RootState) => state.coreConfig.isYumEcomm
  );

  const [{
    enabled: isNewMultiStepDealExperienceEnabled
  }] = useDecision('cb-new_multi_step_deals_experience');
  const { id: dealId } = useSelector(dealSelectors.selectDealSummary);
  const { rawData } = useCCGetDealBuilderQuery({ itemId: dealId });

  const currentStep = useSelector(userSelectionsSelector.selectCurrentStep);
  const stepData = rawData?.steps?.find((step) => step.id === currentStep?.id);
  const recipesFromStepData = stepData?.recipes;

  const analytics = useAnalytics();
  const buttonText = 'Add to Deal';
  const singleStepButtonText = 'Add';
  const { deal } = analytics.analyticsDataModel;

  const classes = useStyles();

  const {
    data: gqlData,
    error,
    storeID
  } = useVersionQueryWithAvailability<
  DisplayableProduct[],
  ProductsByIdQueryResponse,
  CheckAvailabilityVariables
  >({
    queryFn: PRODUCTS_BY_ID(recipes || []),
    storeSpecific: true,
    options: {
      skip: isYumEcomm
    }
  });

  const { recipeOptions } = currentStep ?? { recipeOptions: undefined };

  const data = isYumEcomm && recipeOptions
    ? recipeOptions.reduce((acc: DisplayableProduct[], currentRecipe) => {
      const { displayableProduct } = currentRecipe;
      if (displayableProduct) acc.push(displayableProduct);
      return acc;
    }, [])
    : gqlData;

  const [pageLoadAnalyticsPushed, setPageLoadAnalyticsPushed] = useState(false);
  const filteredData = useApplyDealRulesForNonPizzaProduct(
    data
  ) as DisplayableProduct[];
  const addToDeal = useAddToDeal();
  const dealType = useSelector(dealSelectors.selectDealType);
  const dispatch = useDispatch();
  const router = useRouter();
  const { addToCart } = useAddToCart();
  const [{ enabled: unhideUpsellPageEnabled }] = useDecision('fr-web-3805-yum-unhide-upsell-page');
  const { isShowSodiumAlertForStore } = useSodiumAlertForStore();

  const productsForAnalytics = useMemo(() => {
    try {
      if (filteredData?.length) {
        return getProductsAsItems(filteredData, 'Deals');
      }
      return [];
    } catch (e) {
      return [];
    }
  }, [filteredData]);

  const hasProductsInStock = useMemo(
    () => filteredData?.length && !!filteredData.find((item) => !item.isOutOfStock),
    [filteredData]
  );

  useEffect(() => {
    if (error) {
      dispatch(onGqlError(QueryName.GET_PRODUCTS_BY_ID, error, storeID));
    }
  }, [error, storeID, dispatch]);

  useEffect(() => {
    if (filteredData?.length && !pageLoadAnalyticsPushed) {
      analytics.push(() => onNonPizzaMenuPageLoadInDeal(
        productsForAnalytics,
        deal.deal_id,
        deal.deal_name
      ));
      setPageLoadAnalyticsPushed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData]);

  useDeepEffect(() => {
    if (filteredData?.length && !hasProductsInStock) {
      dispatch(
        openModal({
          ...dealDataOOSModalDetails,
          cta: {
            text: RETURN_TO_DEALS,
            callback: () => {
              router.push(Routes.DEALS);
            }
          },
          hideCloseIcon: true
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData, hasProductsInStock]);

  if (error) return <div data-testid="product-menu-step-error" />;

  const isSingleStepDeal = dealType === DealType.SINGLE_STEP;

  const onSuccessAdd = () => {
    if (isYumEcomm && !unhideUpsellPageEnabled) router.push(Routes.DEALS);
    router.push(Routes.UPSELL);
  };

  const onFailedAdd = () => {
    dispatch(clearDealRecipes());
  };

  const handleAddButtonClick = async (
    selectedDealItem: Product,
    onSuccess: () => void,
    onFailed: () => void,
    fromProductInfoRail?: boolean,
    upcharge?: number
  ): Promise<boolean> => {
    const updatedDeal = addToDeal(selectedDealItem, fromProductInfoRail, upcharge);
    if (updatedDeal && isSingleStepDeal) {
      const combinedFailed = () => {
        onFailed();
        onFailedAdd();
      };
      const combinedSuccess = () => {
        onSuccess();
        onSuccessAdd();
      };
      await addToCart(updatedDeal, combinedSuccess, combinedFailed);
    }
    return !!updatedDeal;
  };

  const handleErrorModal = () => {
    dispatch(openModal(cartErrorModalDetails));
  };

  const sortedProducts = sortBy(
    filteredData,
    (o) => o.priority ?? 0,
    (o) => o.name?.toLowerCase()
  );

  let multiStepButtonDetails: ButtonProps = {
    ariaLabel: buttonText,
    ariaLabelLoading: 'Loading add to deal',
    text: buttonText,
    size: 'small',
    action: 'ADD_TO_DEAL'
  };

  if (isNewMultiStepDealExperienceEnabled) {
    multiStepButtonDetails = {
      ...multiStepButtonDetails,
      color: 'secondary',
      variant: 'outlined'
    };
  }

  return (
    <div data-testid="product-menu-step">
      <CenteredContainer>
        <CategoryHeader
          loadingCategoryName={false}
          pageDisplayName={stepName}
          handleCTAClick={() => null}
          headingText={stepName}
          isLocalized
        />
        {!isNewMultiStepDealExperienceEnabled && (
          <Typography
            variant="h2"
            data-testid="deal-step-subheader"
            className={classes.subheading}
          >
            Build Your Deal
          </Typography>
        )}
        <section className={classes.tilesGrid}>
          {sortedProducts.map((product, index: number) => {
            if (isNewMultiStepDealExperienceEnabled) {
              const recipe = recipesFromStepData?.find((stepDataRecipe) => stepDataRecipe.id === product.id);
              const selectedOption = recipe?.selectedOptions?.find((option) => !!option.variantCode);

              const upchargeBadge = selectedOption?.upcharge ? (
                <ProductUpchargeBadge
                  upchargeAmount={formattedPrice(selectedOption.upcharge, selectedOption.upcharge % 100 === 0)}
                />
              ) : undefined;

              const handleTileButtonClick = (
                selectedDealItem: Product,
                onSuccess: () => void,
                onFailed: () => void,
                fromProductInfoRail?: boolean
              ) => handleAddButtonClick(selectedDealItem, onSuccess, onFailed, fromProductInfoRail, selectedOption?.upcharge);

              return (
                <LocalizedProductTile
                  productIndex={index}
                  key={`deal-product-tile-${product.id}`}
                  categoryName={stepName}
                  showErrorModal={handleErrorModal}
                  handleAddButtonClick={handleTileButtonClick}
                  showPrice={false}
                  showQuantity={false}
                  buttonDetails={
                    isSingleStepDeal
                      ? { text: singleStepButtonText }
                      : multiStepButtonDetails
                  }
                  telemetryEventName="deal-builder-product"
                  isSingleStepDeal={isSingleStepDeal}
                  upchargeBadge={upchargeBadge}
                  {...product}
                />
              );
            }

            return (
              <LocalizedProductTile
                productIndex={index}
                key={`deal-product-tile-${product.id}`}
                categoryName={stepName}
                showErrorModal={handleErrorModal}
                handleAddButtonClick={handleAddButtonClick}
                showPrice={false}
                showQuantity={false}
                buttonDetails={
                  isSingleStepDeal
                    ? { text: singleStepButtonText }
                    : multiStepButtonDetails
                }
                telemetryEventName="deal-builder-product"
                isSingleStepDeal={isSingleStepDeal}
                {...product}
              />
            );
          })}
        </section>
        <CaloriesDisclaimer />
        {isShowSodiumAlertForStore && <SodiumWarningDisclaimer />}
      </CenteredContainer>
    </div>
  );
};

export default ProductMenuStep;
