import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { mobileStartBreakpoint } from '../../../materialUi/theme';

export default makeStyles((theme: Theme) => createStyles({
  addButtonGrid: {
    width: '100%'
  },
  button: {
    fontSize: '16px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      width: '100%'
    },
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      width: '300px'
    }
  },
  addToCart: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  }
}));
