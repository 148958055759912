import React from 'react';

import { CtaButton } from '@/coreComponents/buttons/CtaButton/CtaButton';

type Props = {
  text: string;
  onClick: () => void;
  loading: boolean;
};

const AddDealToCartButton = ({ text, onClick, loading }: Props): JSX.Element => (
  <CtaButton
    size="large"
    labelText={text}
    clickHandler={onClick}
    fullWidth
    loading={loading}
  />
);

export default AddDealToCartButton;
