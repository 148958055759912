import * as React from 'react';
import clsx from 'clsx';
import badgeClasses from './Badge.module.css';

type BadgeVariant = 'primary' | 'secondary' | 'disabled';

interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string | undefined;
  variant?: BadgeVariant;
}

function Badge({ className, variant = 'primary', ...props }: BadgeProps): React.ReactElement {
  return (
    <div className={clsx(badgeClasses.badge, badgeClasses[`badge--${variant}`], className)} {...props} />
  );
}

export { Badge };
