import React from 'react';
import clsx from 'clsx';
import buttonClasses from './Button.module.css';

type ButtonVariant = 'primary' | 'secondary' | 'ghost' | 'link' | undefined;
type ButtonType = 'submit' | 'button' | undefined;
export const buttonVariantClasses = buttonClasses;

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string | undefined;
  type?: ButtonType;
  variant?: ButtonVariant;
}

const Button = React.forwardRef<
HTMLButtonElement,
ButtonProps
>(({
  className, variant = 'primary', type = 'button', ...props
}, ref) => (
  <button
    ref={ref}
    type={type === 'button' ? 'button' : 'submit'}
    className={clsx(
      buttonClasses.button,
      buttonClasses[`button--${variant}`],
      className
    )}
    {...props}
  />
));

Button.displayName = 'Button';

export {
  Button
};
