import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDecision } from '@optimizely/react-sdk';
import { Box } from '@material-ui/core';
import CenteredContainer from '../../../common/CenteredContainer';
import LocalizedPizzaMenu from '../../../menu/pizza/LocalizedPizzaMenu';
import PizzaBuilderContainer from '../../pizza/PizzaBuilderContainer';
import {
  updateDealStep,
  userSelectionsSelector
} from '../slice/userSelections.slice';
import { CurrentStep, DealRecipe } from '../slice/dealTypes';
import StepType from '../StepType';
import ProductMenuStep from './ProductMenuStep/ProductMenuStep';
import DealType from '@/builders/deals/DealTypes';
import { dealSelectors } from '@/builders/deals/slice/deal.slice';
import { RootState } from '@/rootStateTypes';
import { NATIONAL } from '@/localization/constants';
import usePizzaDataInRedux from '@/builders/deals/hooks/usePizzaDataInRedux';
import useDealExitFunction from '@/builders/deals/hooks/useDealExitFunction';
import AddToDealButton from '@/builders/deals/AddToDeal';
import AddDealToCart from '@/builders/deals/AddToDeal/AddDealToCart';
import { onPizzaMenuTileClickInsideDeal } from '../analytics/analytics';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import useDealRecipesPriceHardcoding from '../../../../hardcoding/utils/useDealRecipesPriceHardcoding';
import { PIZZA } from '@/domain/constants';
import { useDealStepPricing } from './useDealStepPricing';
import formattedPrice from '@/common/formattedPrice';
import MultiRecipeSelection from '@/clientCore/menu/components/MultiRecipeSelection';
import DealPrice from '../DealDetails/components/DealPrice';

interface Props {
  isEditFlow?: boolean;
}

interface AddToCartProps extends Props {
  newExperienceEnabled: boolean;
  price: string;
  upchargeAmount?: number;
  showQuantity?: boolean;
}

const SinglePizzaDealAddToCart = ({
  isEditFlow, newExperienceEnabled, price, showQuantity
}: AddToCartProps): JSX.Element => {
  if (newExperienceEnabled) {
    return (
      <>
        <Box display="flex" alignItems="center">
          <Box marginRight={2}>
            <DealPrice price={price} />
          </Box>
          <AddDealToCart
            isEditFlow={isEditFlow}
            showQuantity={showQuantity}
          />
        </Box>
      </>
    );
  }
  return (
    <AddDealToCart
      isEditFlow={isEditFlow}
      showQuantity={showQuantity}
    />
  );
};

const MultiPizzaDealAddToCart = ({ newExperienceEnabled, price, upchargeAmount }: AddToCartProps): JSX.Element => {
  if (newExperienceEnabled) {
    return (
      <>
        <Box display="flex" alignItems="center" flexGrow={1}>
          {upchargeAmount !== 0 && (
            <Box marginRight={2}>
              <DealPrice price={price} />
            </Box>
          )}
          <AddToDealButton upchargeAmount={upchargeAmount} variant="secondary" />
        </Box>
      </>
    );
  }
  return (
    <AddToDealButton variant="primary" />
  );
};

export const DealPizzaBuilder = ({ isEditFlow = false }: Props): JSX.Element => {
  const recipes = useSelector(
    userSelectionsSelector.selectRecipesForCurrentStep
  );
  const dataLoading = usePizzaDataInRedux();
  const dealType = useSelector(dealSelectors.selectDealType);
  const isPizza = recipes?.[0]?.type === PIZZA;
  const isLocalized = useSelector(
    (state: RootState) => state.domain.localization.localizedStore !== NATIONAL
  );
  const { basePrice, totalUpChargeAmount } = useDealStepPricing();
  const [{
    enabled: isSingleStepPizzaDealQuantityPickerEnabled, variationKey
  }] = useDecision('fr-dtg-218-quantity-picker-single-step-pizza-deal');
  const isSingleStepDealQtyPickerABTestOn = isSingleStepPizzaDealQuantityPickerEnabled && variationKey === 'control_qty_pick_pizza_deal_on';

  const [{
    enabled: isSinglePizzaDealDynamicPricingEnabled
  }] = useDecision('dtg-1006-dynamic-pricing-single-pizza-deals');
  const [{ enabled: isNewMultiStepDealExperienceEnabled }] = useDecision('cb-new_multi_step_deals_experience');

  const addToCartButton = useMemo(() => {
    switch (dealType) {
      case DealType.MULTI_STEP:
        return (
          <MultiPizzaDealAddToCart
            newExperienceEnabled={isNewMultiStepDealExperienceEnabled}
            price={`+${formattedPrice(totalUpChargeAmount)}`}
            upchargeAmount={totalUpChargeAmount}
          />
        );
      case DealType.SINGLE_STEP:
        return (
          <SinglePizzaDealAddToCart
            isEditFlow={isEditFlow}
            price={formattedPrice(basePrice + totalUpChargeAmount)}
            newExperienceEnabled={isSinglePizzaDealDynamicPricingEnabled}
            showQuantity={isSingleStepDealQtyPickerABTestOn && isPizza}
          />
        );
      default:
        return (
          <AddDealToCart
            isEditFlow={isEditFlow}
            showQuantity={isSingleStepDealQtyPickerABTestOn && isPizza}
          />
        );
    }
  }, [basePrice, dealType, isEditFlow, isNewMultiStepDealExperienceEnabled, isPizza, isSinglePizzaDealDynamicPricingEnabled, isSingleStepDealQtyPickerABTestOn, totalUpChargeAmount]);

  return (
    <PizzaBuilderContainer
      recipes={recipes}
      isLocalized={isLocalized}
      loading={dataLoading}
      onExit={useDealExitFunction()}
      AddToCartButton={addToCartButton}
    />
  );
};

const SingleRecipe = (props: Props): JSX.Element => (
  <DealPizzaBuilder {...props} />
);

const MultiRecipes = (props: Props): JSX.Element => {
  const recipes = useSelector(
    userSelectionsSelector.selectRecipesForCurrentStep
  );
  const dispatch = useDispatch();
  const step = useSelector(userSelectionsSelector.selectCurrentStep);
  const analytics = useAnalytics();
  const { deal_id: dealId, deal_name: dealName } = analytics.analyticsDataModel.deal;

  const recipesWithUpdatedPrices = useDealRecipesPriceHardcoding(recipes);

  if (!recipesWithUpdatedPrices) return <></>;

  if (step?.recipeId) return <DealPizzaBuilder {...props} />;

  const handlePizzaClick = (recipe: DealRecipe) => {
    const currentStep: Partial<CurrentStep> = {
      ...step,
      recipeId: recipe.id,
      recipeType: recipe.type
    };
    dispatch(updateDealStep(currentStep));

    if (dealId && dealName) {
      const selectedPizzaIndex = recipes?.indexOf(recipe);
      analytics.push(() => onPizzaMenuTileClickInsideDeal(
        dealName,
        dealId,
        recipe,
        selectedPizzaIndex ?? 0
      ));
    }
  };

  return (
    <>
      <CenteredContainer>
        <LocalizedPizzaMenu
          heading="Pizza"
          recipes={recipesWithUpdatedPrices}
          onPizzaClick={handlePizzaClick}
        />
      </CenteredContainer>
    </>
  );
};

const DealStep = ({
  step,
  isEditFlow = false
}: {
  step?: CurrentStep | null;
  isEditFlow?: boolean;
}): JSX.Element => {
  const [{
    enabled: isNewMultiStepDealExperienceEnabled
  }] = useDecision('cb-new_multi_step_deals_experience');
  const dealType = useSelector(dealSelectors.selectDealType);

  switch (step?.type) {
    case StepType.SINGLE_PIZZA_RECIPE: {
      return <SingleRecipe isEditFlow={isEditFlow} />;
    }
    case StepType.MULTI_PIZZA_RECIPE: {
      return isNewMultiStepDealExperienceEnabled && dealType === DealType.MULTI_STEP
        ? <MultiRecipeSelection isEditFlow={isEditFlow} />
        : <MultiRecipes isEditFlow={isEditFlow} />;
    }
    case StepType.MENU_RECIPE: {
      return (
        <ProductMenuStep
          stepName={step.name}
          recipes={step.recipeOptions}
        />
      );
    }
    default: {
      return <></>;
    }
  }
};

export default DealStep;
