import React from 'react';
import clsx from 'clsx';

import {
  ProductTile,
  ProductTileContent,
  ProductDetails,
  ProductTileInfoButton,
  ProductTitle,
  ProductImage,
  ProductSubtitle,
  ProductUpchargeBadge,
  ProductTileCtaSection,
  ProductTileFooter
} from '@shared-components/molecules/ProductTile';
import Button, { buttonVariantClasses } from '@shared-components/atoms/Button';
import Badge from '@shared-components/atoms/Badge';

import css from './MultiRecipeSelection.module.css';

export type RecipeTileData = {
  id: string;
  isCyo: boolean;
  name: string;
  description?: string;
  outOfStock?: boolean;
  imageURL?: string;
  sizeCrustSelection: string;
  upchargeAmount: string;
  onCustomize: () => void;
  onAddToDeal: () => void;
  nutritionInfo: string;
  isMelt?: boolean;
};

type MultiRecipeSelectionTileListProps = {
  product: RecipeTileData;
  stepName?: string;
};

function MultiRecipeSelectionTile({ product, stepName }: MultiRecipeSelectionTileListProps): JSX.Element {
  const {
    isCyo,
    name,
    description,
    outOfStock,
    sizeCrustSelection,
    imageURL,
    upchargeAmount,
    nutritionInfo,
    onCustomize,
    onAddToDeal,
    isMelt
  } = product;

  const title = isCyo ? `${name} ${stepName ?? ''}` : name;
  const testId = isCyo ? 'cyo-pizza-tile' : 'upcharge-recipe-tile';
  const actionRowContent = isCyo ? (
    <Button
      className={
        clsx(
          buttonVariantClasses.button,
          buttonVariantClasses['button--primary'],
          css['product-tile__get-started-cta']
        )
      }
      aria-label={`Get started ${name} ${stepName}`}
      disabled={outOfStock}
      onClick={onCustomize}
    >
      Get Started
    </Button>
  ) : (
    <>
      <Button
        className={css['product-tile__cta-link']}
        aria-label={`Customize ${name}`}
        onClick={onCustomize}
        variant="link"
        disabled={outOfStock}
      >
        Customize
      </Button>
      <Button
        variant="secondary"
        onClick={onAddToDeal}
        aria-label={`Add to deal ${name}`}
        disabled={outOfStock}
      >
        Add to Deal
      </Button>
    </>
  );

  return (
    <ProductTile testId={testId}>
      <ProductTileContent>
        <ProductImage imageURL={imageURL} imageAltText={name} productName={name} />
        <ProductDetails>
          {outOfStock && (<Badge variant="disabled">Sold out</Badge>)}
          <div className={css['product-tile__product-name-crust-container']}>
            <ProductTitle>{title}</ProductTitle>
            {!outOfStock && (
              <ProductTileInfoButton productName={title} productDescription={description} />
            )}
          </div>
          {!isMelt
            && <ProductSubtitle>{sizeCrustSelection}</ProductSubtitle>}
          {upchargeAmount && <ProductUpchargeBadge upchargeAmount={upchargeAmount} />}
        </ProductDetails>
        <ProductTileCtaSection className={isCyo ? css['product-tile__cta-button--cyo'] : ''}>
          {actionRowContent}
        </ProductTileCtaSection>
      </ProductTileContent>
      {nutritionInfo && (<ProductTileFooter>{nutritionInfo}</ProductTileFooter>)}
    </ProductTile>
  );
}

export default MultiRecipeSelectionTile;
