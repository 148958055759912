import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useDecision } from '@optimizely/react-sdk';
import { selectors } from '../../pizza/slice/pizza.slice';
import { clearDeal } from '../slice/deal.slice';
import {
  clearDealToDisplay,
  updateDealRecipes
} from '../slice/userSelections.slice';
import Routes from '../../../router/routes';
import useDeepEffect from '../../../common/useDeepEffect';
import { Deal } from '../slice/dealTypes';
import { RootState } from '@/rootStateTypes';
import { MELT, PIZZA } from '@/domain/constants';
import {
  onAddMultiStepDealToOrderButtonClick,
  onAddMultiStepDealToOrderSuccess
} from '@/builders/deals/analytics/analytics';
import DealType from '../DealTypes';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import AddToCartButton from '@/builders/pizza/AddToCart/AddToCartButton';
import useWindowSize, { isMobile } from '@/common/useWindowSize';
import { DEAL_SUBMIT_BUTTON_TEXT } from '@/deals/constants';

const getDeal = (state: RootState) => state.domain.deal;

interface Props {
  isEditFlow?: boolean;
  showQuantity?: boolean;
}

const AddDealToCart = ({ isEditFlow = false, showQuantity = false }: Props): JSX.Element => {
  const router = useRouter();
  const dispatch = useDispatch();
  const deal: Deal = useSelector(getDeal);
  const isYumEcomm: boolean = useSelector(
    (state: RootState) => state.coreConfig.isYumEcomm
  );
  const windowSize = useWindowSize();
  const defaultText = isMobile(windowSize)
    ? DEAL_SUBMIT_BUTTON_TEXT.mobile
    : DEAL_SUBMIT_BUTTON_TEXT.desktop;
  const buttonText = isEditFlow ? DEAL_SUBMIT_BUTTON_TEXT.edit : defaultText;
  const pizza = useSelector(selectors.selectCartAddablePizza);
  const [{ enabled: unhideUpsellPageEnabled }] = useDecision('fr-web-3805-yum-unhide-upsell-page');

  const analytics = useAnalytics();
  const isMultiStepDeal = deal.data.type === DealType.MULTI_STEP;
  const redirectRoute = isYumEcomm && !unhideUpsellPageEnabled ? Routes.DEALS : Routes.UPSELL;

  useDeepEffect(() => {
    const { recipeType, id: stepId, index } = deal.userSelections.present.currentStep ?? {};
    if (!isMultiStepDeal && (recipeType === PIZZA || recipeType === MELT)) {
      dispatch(updateDealRecipes([{ item: pizza, stepId, index: index || 0 }]));
    }
  }, [pizza]);

  const goToRoute = () => router.push(redirectRoute).then(() => {
    batch(() => {
      dispatch(clearDeal());
      dispatch(clearDealToDisplay());
    });
  });

  const onClickAnalytics = () => {
    analytics.push(() => onAddMultiStepDealToOrderButtonClick(deal));
  };
  const onAddToCartSuccessAnalytics = () => {
    analytics.push(() => onAddMultiStepDealToOrderSuccess(deal));
  };

  return (
    <AddToCartButton
      item={deal}
      quantity={deal?.data?.quantity}
      onFinishedAdd={goToRoute}
      buttonText={buttonText}
      showQuantity={showQuantity}
      onClickAnalytics={isMultiStepDeal ? onClickAnalytics : undefined}
      onSuccessAddToCartAnalytics={
        isMultiStepDeal ? onAddToCartSuccessAnalytics : undefined
      }
      isEditFlow={isEditFlow}
    />
  );
};

export default AddDealToCart;
