import { useSelector } from 'react-redux';
import { useCCGetDealBuilderQuery } from '@/clientCore/temporaryTransformationalHooks/useCCGetDealBuilderQuery';
import { dealSelectors } from '../slice/deal.slice';
import { selectors } from '@/builders/pizza/slice/pizza.slice';
import { userSelectionsSelector } from '../slice/userSelections.slice';
import { PortionChoice } from '@/builders/pizza/dataTransformers/builderTypes';

interface DealStepPricing {
  basePrice: number;
  totalUpChargeAmount: number;
}

export const useDealStepPricing = (): DealStepPricing => {
  const pizzaId = useSelector(selectors.selectPizzaId);
  const deal = useSelector(dealSelectors.selectDeal);
  const selectedSize = useSelector(selectors.selectPizzaSizeOption);
  const selectedCrust = useSelector(selectors.selectPizzaCrust);
  const selectedCheese = useSelector(selectors.selectPizzaCheeseOption);
  const selectedVeggieToppings = useSelector(selectors.selectPizzaVeggieToppings);
  const selectedMeatToppings = useSelector(selectors.selectPizzaMeatToppings);
  const numberOfSelectedToppings = useSelector(selectors.numberOfSelectedToppings);
  const step = useSelector(userSelectionsSelector.selectCurrentStep);

  const actualNumberOfSelectedToppings = [...selectedMeatToppings, ...selectedVeggieToppings].reduce((total, topping) => {
    const extra = 'EXTRA' as PortionChoice;
    if (topping.portion === extra) {
      return total + 1;
    }
    return total;
  }, numberOfSelectedToppings);

  const { rawData } = useCCGetDealBuilderQuery({ itemId: deal?.data.id });

  const stepIndex = step?.index || 0;
  const selectedRecipe = rawData?.steps[stepIndex]?.recipes.find((recipe) => recipe.id === pizzaId);
  const selectedSizeOption = selectedRecipe?.options
    .find((findSize) => findSize.id.toLowerCase().includes('size'))
    ?.modifiers?.find((size) => size.id === selectedSize?.id);
  const selectedCrustOption = selectedSizeOption?.modifiers?.find((crust) => crust.id === selectedCrust?.id);
  const toppingUpchargeLimits = selectedCrustOption?.upcharges
    ?.find((toppingUpcharges) => toppingUpcharges.slotCode.toLowerCase().includes('topping'))
    ?.weightPriceRanges;
  const cheeseUpChargeAmount = selectedCrustOption?.upcharges
    ?.find((cheeseUpcharge) => cheeseUpcharge.slotCode.toLowerCase().includes('cheese'))
    ?.modifiers.flatMap((modifier) => modifier.weights)
    .find((weight) => weight.modifierWeightCode === selectedCheese?.weightCode && weight.price?.amount !== undefined)
    ?.price.amount || 0;

  const toppingUpChargeAmount = toppingUpchargeLimits?.reduce((upCharge, priceRange) => {
    const isInPriceRangeStart = actualNumberOfSelectedToppings > priceRange.rangeStart;
    const isInPriceRangeEnd = !priceRange.rangeEnd || actualNumberOfSelectedToppings < priceRange.rangeEnd;
    if (isInPriceRangeStart && isInPriceRangeEnd) {
      const endOfRange = priceRange.rangeEnd || actualNumberOfSelectedToppings;
      const toppingsInThisRange = endOfRange - priceRange.rangeStart;
      return upCharge + priceRange.price.amount * toppingsInThisRange;
    }

    return upCharge;
  }, 0) || 0;

  const totalVariantUpcharge = selectedCrustOption?.upcharge;

  const totalUpChargeAmount = toppingUpChargeAmount
    + (totalVariantUpcharge ?? 0)
    + cheeseUpChargeAmount;

  const basePrice = rawData?.price || 0;

  return {
    basePrice,
    totalUpChargeAmount
  };
};
