import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';

import { useStyles } from './DealDetailsHeader.styles';

const BUILDER_YOUR_DEAL_TEXT = 'Build your deal';
const STARTING_AT_TEXT = 'Starting at';

type DealDetailsHeaderProps = {
  name: string;
  price: string;
};

const DealDetailsHeader = ({ name, price }: DealDetailsHeaderProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div data-testid="deal-builder-header">
      <Grid item md={7}>
        <Typography
          className={classes.dealName}
          variant="h1"
          data-testid="deal-name"
        >
          {name}
        </Typography>
        <Box
          className={classes.xsMarginLeft}
          fontSize={16}
          marginTop={2}
          marginBottom={1}
        >
          {STARTING_AT_TEXT} {price}
        </Box>
        <Box
          className={`${classes.buildYourDealText} ${classes.xsMarginLeft}`}
          fontSize={14}
          fontWeight={700}
          letterSpacing={1}
          padding="0 0 1.5em 0"
        >
          {BUILDER_YOUR_DEAL_TEXT}
        </Box>
      </Grid>
    </div>

  );
};

export default DealDetailsHeader;
